import React from "react";

import { useAuthClaimCheck, TENANT_PATH, CONTRACT_AND_INVOICES_PATH, MANAGE_SUBSCRIPTION_PATH } from "../../components/profile/shared/AuthClaimCheckHook";
import PageContentContainer from "../../components/profile/shared/PageContentContainer";
import TenantContent from "../../components/profile/TenantContent";

import { pushSAEvent } from "../../helpers";

export default ({ location }) => {
    const loading = useAuthClaimCheck();

    const menuItems = [
        {
            path: TENANT_PATH,
            text: 'My Frends',
            open: true,
        },
        // {
        //     path: CONTRACT_AND_INVOICES_PATH,
        //     text: 'Contracts and Invoices',
        // },
        {
            path: MANAGE_SUBSCRIPTION_PATH,
            text: 'Manage Subscription',
        },
    ];

    pushSAEvent('visit_profile_tenant');

    return (
        <PageContentContainer
            title="Your profile | Tenant"
            location={location}
            loading={loading}
            menuItems={menuItems}
            content={<TenantContent />}
        />
    );
};